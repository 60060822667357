import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0fd827ee&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=coffee"
export * from "./index.vue?vue&type=script&lang=coffee"
import style0 from "./index.vue?vue&type=style&index=0&id=0fd827ee&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd827ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,ButtonVideoLauncher: require('/opt/build/repo/site/components/globals/button-video-launcher.vue').default,Wysiwyg: require('/opt/build/repo/site/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,BtnCraft: require('/opt/build/repo/site/components/globals/btn/craft.vue').default})
