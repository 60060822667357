
import Btn from './btn'

export default
	props:
		button: Object
		size: String

	computed:
		youtubeVideoID: -> @$getYoutubeVideoID(@button.href) if @button.href
		url: -> if @youtubeVideoID then undefined else @button.href

	methods:
		openModal: ->
			return unless @youtubeVideoID
			@$store.commit 'videoModal/play', @button.href

