
export default

	props: block: Object

	computed:

		# Root classes
		classes: -> [
			"height-#{@block.height}"
			"mode-#{@block.spacerType || 'default'}"
		]

