import { render, staticRenderFns } from "./hamburguer.vue?vue&type=template&id=5d598535&scoped=true&lang=pug"
import script from "./hamburguer.vue?vue&type=script&lang=coffee"
export * from "./hamburguer.vue?vue&type=script&lang=coffee"
import style0 from "./hamburguer.vue?vue&type=style&index=0&id=5d598535&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d598535",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccessibilityBtn: require('/opt/build/repo/site/components/globals/accessibility-btn.vue').default})
