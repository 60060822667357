
import chunk from 'lodash/chunk'
export default

	data: -> links:
		Technology: '/technology'
		Operations: '/operations'
		Vehicle: '/vehicle'
		Safety: '/safety'
		Sustainability: '/sustainability'
		"First Responders": "/first-responders"


		About: '/about'
		Press: '/press'
		Careers: '/careers'
		Blog: 'https://medium.com/nuro'
		Contact: '/contact'
		FAQs: '/faqs'
		# Partners: '/partners'

	computed: linkColumns: -> chunk Object.entries(@links), 6

