
import HeaderMixin from './header-mixin'
import dimensions from '~/assets/vars/dimensions'
export default

	mixins: [ HeaderMixin ]

	data: ->
		height: parseInt dimensions['header-h']
		scrollPosition: null

	computed:
		maskStyle: -> if @scrollPosition then "transform: translateY(#{-@scrollPosition}px)"

	methods:
		scroll: (scrollValue) ->
			@scrollPosition = scrollValue

