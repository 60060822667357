
import chunk from 'lodash/chunk'
import breakpoints from '/assets/vars/breakpoints.json'
###
TODO:
	- Fix issue with body scroll lock not allowing scrolling on target element
###
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks
} from 'body-scroll-lock'
export default
	props: open: Boolean

	data: ->
		links:
			Technology: '/technology'
			Operations: '/operations'
			Vehicle: '/vehicle'

			Safety: '/safety'
			Sustainability: '/sustainability'
			FAQs: '/faqs'

			About: '/about'
			Press: '/press'
			Careers: '/careers'

			Blog: 'https://medium.com/nuro'
			Contact: '/contact'
			"First Responders": '/first-responders'
			# Partners: '/partners'
			# Communities: '/communities'

		linksMobile:
			Technology: '/technology'
			About: '/about'
			Operations: '/operations'
			Press: '/press'
			Vehicle: '/vehicle'
			Careers: '/careers'
			Safety: '/safety'
			Blog: 'https://medium.com/nuro'
			Sustainability: '/sustainability'
			Contact: '/contact'
			FAQs: '/faqs'
			"First Responders": '/first-responders'

			# Partners: '/partners'
			# Communities: '/communities'

		# body-scroll-lock not working properly, causing
		# the menu to be unscrollable
		# variables needed to manually scroll the container
		scrollPosition: 0 # The scrolling position of the menu
		height: null # The height of the menu
		shouldScroll: true # Should allow scrolling the menu further down
		dragging: null # The menu is being scrolled by touch events
		startPointer: null # Where was the mouse when the drag started
		lastPointer: null # The last position of the pointer
		viewportWidth: null # The viewport width

	computed:
	 	# The news to be displayed
		news: -> @$store.state.globals.headerNews.news

		# Scroll the menu
		maskStyle: -> "transform: translateY(#{-@scrollPosition}px)"

		# Links grouped by columns
		columnLinks: -> chunk Object.entries(@links), 3

	# Setup Listeners
	mounted: ->
		@getDimensions()
		window.addEventListener 'resize', @onResize

	# Cleanup
	beforeDestroy: ->
		window.removeEventListener 'resize', @onResize

	watch:
		# Toggle body scroll on and off
		open: (open) ->
			if open then @$nextTick =>
			 	# Update height
				@getDimensions()

				# Disable body scroll
				@$scroller.disable @$refs.menu

				# Add open menu class to content div
				content = document.querySelector('#max-w-full')
				content.classList.add 'menu-open'

				# Set up observer to limit menu scrolling
				@observer = new IntersectionObserver @handleObserver,
					root: null
					rootMargin: '0px'
					threshold: 1
				@observer.observe @$refs.observer

			# Clean up and reset scroll position
			else
			 	# Cleanup observer
				@observer.disconnect?()

				# Reset menu scroll position
				@$wait 600, =>
					@scrollPosition = 0
					@$emit 'scrollValue', @scrollPosition

				# remove open menu class from content div
				content = document.querySelector('#max-w-full')
				content.classList.remove 'menu-open'

				# Enable body scroll
				@$scroller.enable @$refs.menu

		# Setup touch drag listeners
		dragging: (dragging) ->
			if dragging
				@preventContentDrag()
				window.addEventListener 'touchmove', @moveCallback
				window.addEventListener 'touchend', @upCallback
			else
				window.removeEventListener 'touchmove', @moveCallback
				window.removeEventListener 'touchend', @upCallback


	methods:

		# Get initial pointer coords
		initializePointers: (pointerEvent) ->
			@startPointer = @lastPointer = @getPointerCoords pointerEvent

		# Helper to get coordinates
		getPointerCoords: (pointerEvent) ->
			result =
				x: pointerEvent.touches?[0]?.pageX || pointerEvent.pageX
				y: pointerEvent.touches?[0]?.pageY || pointerEvent.pageY
			return result

		# Stop listening for pointer movement
		upCallback: -> @dragging = false

		# Scroll the menu
		moveCallback: (pointerEvent) ->
			pointer = @getPointerCoords pointerEvent
			velocity = pointer.y - @lastPointer.y
			@lastPointer = pointer

			return if !@shouldScroll and velocity < 0
			@scrollPosition = Math.max Math.min(@scrollPosition - velocity, @height), 0
			@$emit 'scrollValue', @scrollPosition

		# Scroll the menu
		scroll: (direction) ->
			return if !@shouldScroll and direction == 'down'
			delta = if direction == 'down' then window.innerHeight/10 else -window.innerHeight/10
			@scrollPosition = Math.max Math.min(@scrollPosition + delta, @height), 0
			@$emit 'scrollValue', @scrollPosition

		# Stop scrolling down
		handleObserver: (entries, observer) ->
			entries.forEach (entry) =>
				@shouldScroll = !entry.isIntersecting

		# Setup touch movement
		pointerDown: (pointerEvent) ->
			@initializePointers pointerEvent
			@dragging = true

		# Scroll on wheel movement
		onWheel: (event) ->
		 	# Don't scroll further if at the end of menu
			return if !@shouldScroll and event.deltaY > 0
			@scrollPosition = Math.max Math.min(@scrollPosition + event.deltaY, @height), 0
			@$emit 'scrollValue', @scrollPosition

		# Get menu height and viewport width
		getDimensions: ->
			@height = @$refs.menu?.offsetHeight
			@viewportWidth = window.innerWidth

		# Update dimensions
		onResize: ->	@getDimensions()

		# Prevent the anchors and images from being draggable (like via their
		# ghost outlines). Using this approach because the draggable html attribute
		# didn't work in FF.  This only needs to be run once.
		preventContentDrag: ->
			return if @contentDragPrevented
			@$refs.menu.querySelectorAll 'a, img'
			.forEach (el) -> el.addEventListener 'dragstart', (e) ->
				e.preventDefault()
			@contentDragPrevented = true

		enter: ->
			@$wait 50, =>
				elements = if @viewportWidth >= parseInt breakpoints['nav-break'] then @getDesktopElementsToStagger() else @getMobileElementsToStagger()
				@leaveTimeline?.pause()
				@enterTimeline = @$gsap.timeline()
					.pause()
					.set elements, {opacity: 0, y: -30}
					.to elements, {duration: 0.5, y: 0, stagger: 0.15, opacity: 1}, 0.1

				@enterTimeline.play 0

		leave: ->
			news = @getNewsElements()
			columns = @getColumnsElements()
			@enterTimeline?.pause()
			@leaveTimeline = @$gsap.timeline()
				.pause()
				.to news, {duration: 0.3, opacity: 0, y: -30}, 0
				.to columns, {duration: 0.5, opacity: 0, y: -30}, 0

			@leaveTimeline.play()

		getNewsElements: ->
			heading = @$el.querySelectorAll '.news-heading'
			news = @$el.querySelectorAll '.news a'
			Array.from(heading).concat(Array.from(news))

		getColumnsElements: -> Array.from @$el.querySelectorAll '.column'

		getMobileLinkElement: -> Array.from @$el.querySelectorAll '.links.hide-desktop'

		getDesktopElementsToStagger: -> @getColumnsElements().concat(@getNewsElements())

		getMobileElementsToStagger: -> @getMobileLinkElement().concat(@getNewsElements())

