
export default

	data: -> links:
		Twitter: 'https://twitter.com/nuro'
		LinkedIn: 'https://www.linkedin.com/company/nuro-inc.'
		Youtube: 'https://www.youtube.com/c/NuroTeam'
		Facebook: 'https://www.facebook.com/nurobots'
		Instagram: 'https://www.instagram.com/nuroteam'

