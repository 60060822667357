
export default
	props:
		show:	default: true
		onClick: type: Function
		renderAsDiv: type: Boolean
		color:
			type: String
			default: "yellow"
			validator: (val) -> val in ["yellow", "yellow-sun"]
		size:
			type: String
			default: "normal"
			validator: (val) -> val in ["normal", "small"]

	computed:
		component: -> if @renderAsDiv then 'div' else 'button'
		classes: -> [
			"background-#{@color || 'yellow'}"
			"size-#{@size || 'normal'}"
		]

	methods: click: -> @$emit 'click'
