
import { padNum } from '~/services/helpers'
export default
	props: block: Object

	data: ->
		active: 0
		paginationRefresher: 0
		autoplayInterval: null

	inject: ["blockIndex"]

	computed:
		classes: -> [
			@activeSlide.horizontalAlignment || "left"
			"marquee" if @blockIndex == 0
		]

		textColorStyle: -> color: @activeSlide?.textColor || "black"

		activeSlide: -> @block.slides[@active]

		imageClasses: -> [
			"overlay-intensity-#{@activeSlide.overlayIntensity || 'low'}" if @activeSlide.overlayIntensity != "no-overlay"
			"overlay-type-#{@activeSlide.overlayType || 'solid'}" if @activeSlide.overlayIntensity != "no-overlay"
		]

		imageDesktop: -> if @activeSlide?.imageDesktop.length then @activeSlide?.imageDesktop else @activeSlide?.imageMobile
		imageMobile: -> if @activeSlide?.imageMobile.length then @activeSlide.imageMobile else @activeSlide.imageDesktop

		videoDesktop: -> if @activeSlide?.videoDesktop?.length then @activeSlide.videoDesktop else @activeSlide.videoMobile
		videoMobile: -> if @activeSlide?.videoMobile?.length then @activeSlide.videoMobile else @activeSlide.videoDesktop

		headline: -> @activeSlide?.headline
		kicker: -> @activeSlide?.kicker
		copy: -> @activeSlide?.copy
		unorphan: -> @activeSlide?.unorphan
		balanceText: -> @activeSlide?.balanceText
		copy: -> @activeSlide?.copy

		buttons: -> @activeSlide?.buttons
		pageOffset: ->
			"--margin-left-desktop": "#{@active*70}px"
			"--margin-left-mobile": "#{@active*40}px"
		activePage: -> padNum @active + 1

		autoplayDelay: -> ( parseInt(@block.autoplayDelay) || 3) * 1000

	watch: active: -> @paginationRefresher += 1

	mounted: ->
		window.addEventListener 'resize', @getPaginationDimension
		@autoplayInterval = setInterval @nextSlide, @autoplayDelay

	beforeDestroy: ->	window.removeEventListener 'resize', @getPaginationDimension

	methods:
		goTo: (index) ->
			clearInterval @autoplayInterval
			@active = index
			@autoplayInterval = setInterval @nextSlide, @autoplayDelay

		getImageDesktop: (slide) -> if slide?.imageDesktop.length then slide?.imageDesktop else slide?.imageMobile
		getImageMobile: (slide) -> if slide?.imageMobile.length then slide?.imageMobile else slide?.imageDesktop

		getVideoDesktop: (slide) -> if slide?.videoDesktop?.length then slide?.videoDesktop else slide?.videoMobile
		getVideoMobile: (slide) -> if slide?.videoMobile?.length then slide?.videoMobile else slide?.videoDesktop

		play: (youtubeVideoUrl) -> @$store.commit 'videoModal/play', youtubeVideoUrl

		nextSlide: -> @active = (@active+1) % @block.slides.length

