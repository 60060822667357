
import { kebabCase } from '~/services/helpers.coffee'
import ClickOutside from 'vue-click-outside'

export default
	name: 'Modal'

	directives: {ClickOutside}

	props:

		open: Boolean

		# When true, shows close 'x' and clicking background closes modal
		closeable:
			type: Boolean
			default: true

	computed:

		classes: -> [
			if @open then 'open'
		]

	watch:
		# When open, enable body scroll lock
		open:
			immediate: true
			handler: ->
				if @open then return @$nextTick =>

					# Modal is open
					# Lock scroll
					el = @$refs.scrollable
					return unless el # Won't exist during SSG
					@$scroller.disable @scrollable = @$refs.scrollable

				# Modal is closed
				# Unlock scroll
				return unless @scrollable # Won't exist during SSG
				@$scroller.enable @scrollable

	methods:

		# Remove it after the transition ends
		remove: ->
			@$emit "destroy"  # Got destroyed?  Better tell your parents.
			@$destroy()
			@$el.remove()

		close: ->
			@$emit 'close'

		onBgClick: -> if @closeable then @close()

		# Click if the target is directly the slot, which means that the slotted element
		# is not as wide as the viewport, so clicking on the slot should behave like
		# clicking on the background
		onSlotClick: (event) ->
			if event.target.matches('.slot[role="dialog"]') and @closeable then @close()

