import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=cdc08288&scoped=true&lang=pug"
import script from "./btn.vue?vue&type=script&lang=coffee"
export * from "./btn.vue?vue&type=script&lang=coffee"
import style0 from "./btn.vue?vue&type=style&index=0&id=cdc08288&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdc08288",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Squircle: require('/opt/build/repo/site/components/globals/squircle/index.vue').default})
